import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./pages/Home/home";
import Only from "./pages/Only/only";
import Programacao from "./pages/Programacao/programacao";
import { analysticsEvent } from "./services/index";

const _redirect = async (event, link) => {
  try {
    await analysticsEvent(event);
  } catch (err) {
  } finally {
    window.location.replace(link);
  }
  return <></>;
};

const Routes = () => (
  <Router>
    <Route path="/" exact component={Home} />

    <Route path="/only" component={Only} />
    <Route path="/onlyfans" component={Only} />
    <Route path="/privacy" component={Only} />
    <Route path="/programacao" component={Programacao} />

    <Route
      path="/live"
      component={() =>
        _redirect("REDIRECT_LIVE", "https://www.twitch.tv/lipinhor")
      }
    />

    <Route
      path="/donate"
      component={() =>
        _redirect("REDIRECT_DONATE", "https://www.streamar.com.br/LipinhO")
      }
    />

    <Route
      path="/store"
      component={() =>
        _redirect("REDIRECT_STORE", "https://streamelements.com/lipinhor/store")
      }
    />

    <Route
      path="/loja"
      component={() =>
        _redirect("REDIRECT_LOJA", "https://streamelements.com/lipinhor/store")
      }
    />

    <Route
      path="/youtube"
      component={() =>
        _redirect("REDIRECT_COMMANDS", "https://www.youtube.com/@LipinhO")
      }
    />

    <Route
      path="/instagram"
      component={() =>
        _redirect("REDIRECT_INSTAGRAM", "https://www.instagram.com/LipinhOx_")
      }
    />

    <Route
      path="/facebook"
      component={() =>
        _redirect("REDIRECT_FACEBOOK", "https://www.facebook.com/LiipinhO")
      }
    />

    <Route
      path="/dc"
      component={() =>
        _redirect("REDIRECT_DC", "https://discord.com/invite/boteco-do-lipinho-852868481350500392")
      }
    />

    <Route
      path="/discord"
      component={() =>
        _redirect("REDIRECT_DISCORD", "https://discord.com/invite/boteco-do-lipinho-852868481350500392")
      }
        
    />
    <Route
      path="/1xbet"
      component={() =>
        _redirect("REDIRECT_1XBET", "https://clcr.me/jPv52h")
      }
        
    />        
    <Route
      path="/parimatch"
      component={() =>
        _redirect("REDIRECT_PARIMATCH", "https://bit.ly/4gvuIwe")
      }
    />        

    <Route
      path="/pari"
      component={() =>
        _redirect("REDIRECT_PARIMATCH", "https://bit.ly/4gvuIwe")
      }
    />       

    <Route
      path="/bet"
      component={() =>
        _redirect("REDIRECT_PARIMATCH", "https://bit.ly/4gvuIwe")
      }
    />    

    <Route
      path="/leonbet"
      component={() =>
        _redirect("REDIRECT_LEONBET", "https://leon.casino/pt-br/registration?retentionId=483862de-0c01-4897-bf9d-a76eb16a7387&utm_source=brazil&utm_campaign=smart&utm_medium=lipinho&qtag=a37030_t43988_c311_lipinho")
      }
    />      
        
    <Route
      path="/brabet"
      component={() =>
        _redirect("REDIRECT_BRABET", "https://www.2brabet.com/?agentid=188486744")
      }
    />    

    <Route
      path="/tada"
      component={() =>
        _redirect("REDIRECT_BRABET", "https://www.2brabet.com/?agentid=188486744")
      }
    />    
        
    <Route
      path="/doar"
      component={() =>
        _redirect("REDIRECT_DOAR", "https://streamar.com.br/LipinhO")
      }
    />

    <Route
      path="/doacao"
      component={() =>
        _redirect("REDIRECT_DOACAO", "https://streamar.com.br/LipinhO")
      }
    />
    <Route
      path="/pix"
      component={() => _redirect("REDIRECT_PIX", "https://livepix.gg/lipinho")}
    />

    <Route
      path="/tiktok"
      component={() =>
        _redirect("REDIRECT_TIKTOK", "https://www.tiktok.com/@lipinhox")
      }
    />

    <Route
      path="/telegram"
      component={() =>
        _redirect("REDIRECT_TELEGRAM", "https://t.me/+ps1YQcVkxgI5YTRh")
      }
    />
        
    <Route
      path="/canalinstagram"
      component={() =>
        _redirect("REDIRECT_CANALINSTA", "https://ig.me/j/AbagKTXjuEFleRSi/")
      }
    />
        
    <Route
      path="/onestate"
      component={() =>
        _redirect("REDIRECT_ONESTATE", "https://onestate.onelink.me/nWr0/lipinho")
      }
    />
        
    <Route
      path="/onestaterp"
      component={() =>
        _redirect("REDIRECT_ONESTATERP", "https://onestate.onelink.me/nWr0/lipinho")
      }
    />
  </Router>
);

export default Routes;
